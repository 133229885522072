import { render, staticRenderFns } from "./HowItWorksMore.vue?vue&type=template&id=97a41492&"
import script from "./HowItWorksMore.vue?vue&type=script&lang=js&"
export * from "./HowItWorksMore.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/XDCWallet/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('97a41492')) {
      api.createRecord('97a41492', component.options)
    } else {
      api.reload('97a41492', component.options)
    }
    module.hot.accept("./HowItWorksMore.vue?vue&type=template&id=97a41492&", function () {
      api.rerender('97a41492', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components-default/HowItWorksMore.vue"
export default component.exports