var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "component-container" },
    [
      _c("div", { staticClass: "py-6 py-md-12" }),
      _c(
        "v-container",
        [
          _c(
            "v-sheet",
            { staticClass: "mb-12", attrs: { "max-width": "600px" } },
            [
              _c("app-block-title", {
                staticClass: "mb-8",
                attrs: { "no-page-title": "", data: _vm.titleData },
              }),
            ],
            1
          ),
          _c(
            "v-row",
            _vm._l(_vm.blockContent, function (content) {
              return _c(
                "v-col",
                {
                  key: content.key,
                  staticClass: "pb-12",
                  attrs: { cols: "12", sm: "6", md: "6", lg: "4" },
                },
                [
                  _c("img", { attrs: { src: content.img, height: "60" } }),
                  _c("h5", { staticClass: "font-weight-bold mt-3" }, [
                    _vm._v(" " + _vm._s(_vm.$t(content.title)) + " "),
                  ]),
                  _c("div", { staticClass: "mt-3 pr-md-12" }, [
                    _vm._v(" " + _vm._s(_vm.$t(content.description)) + " "),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }